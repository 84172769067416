import LoginLayout from '@components/layout/LoginLayout'
import { useAuth } from '@hooks'
import { useUpdateUserAttributes } from '@hooks/useUpdateUserAttributes'
import { Button, Stack, Text, TextInput, Title } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useI18nContext } from '@packages/i18n'
import type { CognitoUserAttributes } from '@packages/types'
import { userAttributesSchema } from '@utils/auth-utils'
import { translateCognitoError } from '@utils/error-utils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

type RequiredUserAttributes = Pick<
  Required<CognitoUserAttributes>,
  'family_name' | 'given_name'
> &
  Pick<CognitoUserAttributes, 'picture'>

const SetupProfilePage = ({ username }: { readonly username: string }) => {
  const { userAttributes, switchUser } = useAuth()

  const { updateUserAttributes } = useUpdateUserAttributes()

  const { LL } = useI18nContext()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const form = useForm<RequiredUserAttributes>({
    initialValues: {
      family_name: userAttributes.family_name ?? '',
      given_name: userAttributes.given_name ?? '',
      picture: userAttributes.picture ?? '',
    },
    validate: zodResolver(userAttributesSchema),
  })

  const handleSubmit = async (values: RequiredUserAttributes) => {
    setIsLoading(true)
    try {
      await switchUser({ navigate, username })
      await updateUserAttributes(values)
    } catch (error) {
      setErrorMessage(translateCognitoError(LL, error))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <LoginLayout>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <div className='mt-20'>
          <Title mb='xs' order={5}>
            {LL.auth.onboarding.headline()}
          </Title>
          <Text mb='lg'>{LL.auth.onboarding.headlineDescription()}</Text>
          <Stack>
            <TextInput
              label={LL.settingsPage.personal.profile.firstName()}
              withAsterisk
              {...form.getInputProps('given_name')}
              id='given_name'
            />
            <TextInput
              id='family_name'
              label={LL.settingsPage.personal.profile.lastName()}
              withAsterisk
              {...form.getInputProps('family_name')}
            />
            {errorMessage && (
              <Text c='red' mt='md'>
                {errorMessage}
              </Text>
            )}
            <Button fullWidth loading={isLoading} mt='md' type='submit'>
              {LL.buttons.continue()}
            </Button>
          </Stack>
        </div>
      </form>
    </LoginLayout>
  )
}

export default SetupProfilePage
