import type { ApiUserProfile } from '@types'

/**
 * Extracts probable name parts from an email address.
 * @param email - The email address.
 * @returns An array of string extracted from the email address.
 */
const getPartsFromEmail = (email: string): string[] => {
  // take the first part of the email address
  const [name] = email.split('@')[0]?.split('+') ?? []
  // split the name by dots and remove empty parts
  const parts = name?.split('.').filter(Boolean) ?? []

  const capitalizedParts =
    parts
      ?.map((part) => {
        // capitalize the first letter of each part
        const [firstLetter, ...rest] = part
        if (!firstLetter) return ''

        return `${firstLetter.toUpperCase()}${rest.join('')}`
      })
      .filter(Boolean) ?? []

  return capitalizedParts
}

/**
 * Generates the user's display name based on their attributes.
 * @param userAttributes - The user's attributes.
 * @returns The user's display name.
 */
export const getUserDisplayName = (
  userAttributes?: ApiUserProfile['userAttributes']
): string | '' => {
  let parts: Array<string | undefined>

  if (!userAttributes) {
    return ''
  }

  if (userAttributes.formattedName) return userAttributes.formattedName

  if (userAttributes.combinedName) {
    if (userAttributes.combinedName.includes('@')) {
      // some users have their email as their name
      parts = getPartsFromEmail(userAttributes.combinedName)
    } else {
      // when the user has a proper name, take it
      return userAttributes.combinedName
    }
  }

  // some users have a given and/or family name
  parts = [userAttributes.given_name, userAttributes.family_name].filter(
    Boolean
  )

  if (!parts.length && userAttributes.email) {
    parts = getPartsFromEmail(userAttributes.email)
  }

  const joinedName = parts
    .map((part) => part?.trim())
    .filter(Boolean)
    .join(' ')

  return joinedName || ''
}

/**
 * Generates the user's initials based on their attributes or name.
 * @param props - The user's attributes or name.
 * @returns The user's initials.
 */
export const getAvatarInitials = (
  props: ApiUserProfile['userAttributes'] | string
) => {
  let name =
    typeof props === 'string'
      ? props?.toUpperCase()
      : getUserDisplayName(props)?.toUpperCase()

  if (name?.includes('@')) name = getPartsFromEmail(name).join(' ')

  if (!name) {
    return null
  }

  const nameParts = name.split(' ')

  if (nameParts.length === 1) {
    return (nameParts[0]?.charAt(0) ?? '') + (nameParts[0]?.charAt(1) ?? '')
  }

  return `${nameParts[0]?.charAt(0)}${nameParts[nameParts.length - 1]?.charAt(
    0
  )}`
}

export const getCategorizedMembers = (members: ApiUserProfile[]) => {
  const activeMembers = members.filter(
    (member) =>
      member.membership.isActive &&
      member.accountActive &&
      member.membershipActive
  )
  const inactiveMembers = members.filter(
    (member) =>
      !member.membership.isActive ||
      !member.accountActive ||
      !member.membershipActive
  )

  return {
    activeMembers,
    inactiveMembers,
  }
}
