import { useQueryClient } from '@tanstack/react-query'
import type { AuthSettings, DefaultAuthInfo } from '@types'
import { sleep } from '@utils'
import { addAmplifyConfiguration } from '@utils/auth-utils'
import {
  clearLoginLocalStorage,
  getCurrentAmplifyUsername,
  setCurrentAmplifyUser,
} from '@utils/localstorage-utils'
import { manuallyFetchAccountResponse } from '@utils/trpc'
import {
  type AuthUser,
  signOut as amplifySignOut,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  signInWithRedirect,
} from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import type { UserAccount } from 'backend/shared/db'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { useAuthLocalStorage } from './useAuthLocalStorage'

export const useProvideAuth = (defaultAuthInfo: DefaultAuthInfo) => {
  const {
    setSsoConfiguration,
    ssoConfiguration,
    setTenantId,
    tenantId,
    addSession,
    sessions,
    updateSession,
    removeSession,
    enteredViaSSO,
    setSessions,
  } = useAuthLocalStorage()

  const queryClient = useQueryClient()

  const [currentUser, setCurrentUser] = useState<AuthUser | undefined>()
  const [sessionRestoreFinished, setSessionRestoreFinished] = useState(false)
  const [sessionRestoreRunning, setSessionRestoreRunning] = useState(false)
  const [userRestoreFinished, setUserRestoreFinished] = useState(false)

  const configureAmplify = useCallback(
    (authSettings?: AuthSettings) => {
      if (authSettings) {
        addAmplifyConfiguration({
          ...defaultAuthInfo,
          identityProviders: [
            ...defaultAuthInfo.identityProviders,
            ...authSettings.identityProviders,
          ],
        })
      } else {
        addAmplifyConfiguration(defaultAuthInfo)
      }
    },
    [defaultAuthInfo]
  )

  const reloadAccount = useCallback(
    async (options?: {
      forceRefresh?: boolean
      setAsCurrentUser?: boolean
    }) => {
      const currentUsername = structuredClone(getCurrentAmplifyUsername())

      if (!currentUsername) return

      try {
        const [loadedUser, tokenUserAttributes, authSession] =
          await Promise.all([
            getCurrentUser(),
            fetchUserAttributes(),
            fetchAuthSession({ forceRefresh: options?.forceRefresh }),
          ])
        const idToken = authSession.tokens?.idToken?.toString()

        if (!(loadedUser && idToken && tokenUserAttributes)) return

        const previousSession = sessions[loadedUser.username]

        let providerName = previousSession?.providerName

        const username = loadedUser.username

        if (enteredViaSSO && username.includes('_')) {
          const potentialProviderName = username.split('_')[0]
          if (
            ssoConfiguration?.identityProviders.find(
              (provider) => provider.providerName === potentialProviderName
            )
          ) {
            providerName = potentialProviderName
          }
        }

        const shouldRefreshAccount =
          Boolean(options?.forceRefresh) ||
          !previousSession ||
          Boolean(
            new Date(previousSession.lastRefreshTime).getTime() + 1_000 * 5 <
              Date.now()
          )

        const account =
          previousSession?.latestAccountInfo && !shouldRefreshAccount
            ? previousSession.latestAccountInfo
            : await manuallyFetchAccountResponse({ idToken })

        if (!account) return

        addSession({
          account,
          authSettings: enteredViaSSO
            ? (ssoConfiguration ?? undefined)
            : undefined,
          fetchAuthSessionResult: authSession,
          providerName,
          user: loadedUser,
          tokenUserAttributes,
        })

        if (options?.setAsCurrentUser) {
          setCurrentUser(loadedUser)
        }

        setSessionRestoreFinished(true)
      } catch {
        updateSession({
          data: {
            authenticated: false,
          },
          username: currentUsername,
        })
      }
    },
    [addSession, enteredViaSSO, sessions, ssoConfiguration, updateSession]
  )

  const restoreSessions = useCallback(
    async (options?: { forceRefresh?: boolean }) => {
      if (sessionRestoreRunning) return

      setSessionRestoreRunning(true)

      const latestAmplifyUsername = structuredClone(getCurrentAmplifyUsername())
      await reloadAccount({
        forceRefresh: options?.forceRefresh,
        setAsCurrentUser: true,
      })
      setUserRestoreFinished(true)

      for (const session of Object.values(sessions)) {
        if (latestAmplifyUsername === session.username) continue

        setCurrentAmplifyUser({ username: session.username })
        await reloadAccount({ forceRefresh: options?.forceRefresh })
      }

      setCurrentAmplifyUser({ username: latestAmplifyUsername ?? undefined })

      setSessionRestoreFinished(true)
      setSessionRestoreRunning(false)
    },
    [reloadAccount, sessionRestoreRunning, sessions]
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (ssoConfiguration && window.location.search.includes('code')) {
      configureAmplify(ssoConfiguration)
    } else {
      configureAmplify()
      void restoreSessions()
    }
  }, [ssoConfiguration])

  const user = useMemo(() => {
    const session = sessions[currentUser?.username ?? '']

    if (!session) return undefined

    const { latestAccountInfo, userId, username, authenticated, meta } = session

    const userAttributes = latestAccountInfo.account

    const customUserId = latestAccountInfo.account.userId

    return {
      account: latestAccountInfo.account,
      authenticated,
      invitations: latestAccountInfo.invitations,
      teams: latestAccountInfo.teams,
      userAttributes,
      legacyUserId: userId,
      userId: customUserId,
      username,
      ...meta,
    }
  }, [currentUser, sessions])

  const tenant = useMemo(() => {
    return user?.teams?.find((team) => team.tenantId === tenantId)
  }, [tenantId, user?.teams])

  const permissions = useMemo(() => {
    return tenant?.permissions ?? []
  }, [tenant])

  /*
   * Auth useEffect, starts a Hub that listens for auth events such as
   * - configured: A tenant has been set, and userpools etc. have been set
   * - signIn: A user has successfully signed in
   * - signOut: A user has signed out
   */
  useEffect(() => {
    const listener = Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signedIn') {
        setCurrentAmplifyUser({ username: payload.data.username })
        void reloadAccount({ setAsCurrentUser: true })
      } else if (payload.event === 'signedOut') {
        setTenantId(undefined)
      }
    })

    return () => listener()
  }, [setTenantId, reloadAccount])

  const ssoSignIn = useCallback(
    async (providerName: string, authSettings: AuthSettings) => {
      configureAmplify(authSettings)
      setSsoConfiguration(authSettings)
      setCurrentAmplifyUser({ username: undefined })

      await signInWithRedirect({
        provider: {
          custom: providerName,
        },
      })
    },
    [configureAmplify, setSsoConfiguration]
  )

  const signOut = useCallback(
    async (username: string) => {
      await amplifySignOut().catch(() => {
        return
      })
      removeSession({ username })
    },
    [removeSession]
  )

  const signOutAll = useCallback(() => {
    clearLoginLocalStorage()
    setSessions({})
    setCurrentUser(undefined)
  }, [setSessions])

  const clearTenant = useCallback(() => {
    setTenantId(undefined)
  }, [setTenantId])

  const switchUser = useCallback(
    async (props: {
      navigate: NavigateFunction
      navigateTo?: string
      tenantId?: string
      username: string
    }) => {
      queryClient.clear()
      setCurrentAmplifyUser({
        username: props.username,
      })

      // navigate to "/" if going to pick tenant or switching tenant
      if (!props.tenantId || props.tenantId !== tenantId) {
        props.navigate(props.navigateTo ?? '/', { replace: true })
      }

      if (!user || user?.username !== props.username) {
        await reloadAccount({ setAsCurrentUser: true })
      } else {
        await sleep(100)
      }

      if (props.tenantId) setTenantId(props.tenantId)
      else clearTenant()
    },
    [clearTenant, queryClient, reloadAccount, setTenantId, tenantId, user]
  )

  const userAttributes = useMemo(
    (): Partial<UserAccount> => user?.userAttributes ?? {},
    [user]
  )

  return {
    clearTenant,
    configureAmplify,
    permissions,
    reloadAccount,
    restoreSessions,
    sessionRestoreFinished,
    sessionRestoreRunning,
    sessions,
    signOut,
    signOutAll,
    ssoSignIn,
    switchUser,
    tenant,
    tenantId,
    user,
    userAttributes,
    userId: user?.userId,
    userRestoreFinished,
  }
}
