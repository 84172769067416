import { getResolvedDomains } from '@packages/constants'
import type { DefaultAuthInfo } from '@types'
import { Amplify } from 'aws-amplify'
import {
  type FetchUserAttributesOutput,
  fetchAuthSession,
} from 'aws-amplify/auth'
import { z } from 'zod'
import type { ApiUserProfile } from '../types/trpc-types'
import type { authSessionShape } from './localstorage-utils'

export const userAttributesSchema = z.object({
  family_name: z.string().min(2, { message: 'Invalid last name' }).max(50, {
    message: 'Invalid last name',
  }),
  given_name: z.string().min(2, { message: 'Invalid first name' }).max(50, {
    message: 'Invalid first name',
  }),
})

export const getCurrentAuthConfig = () => {
  const config = Amplify.getConfig().Auth?.Cognito
  if (!config) throw new Error('Auth config not found')

  return {
    appClientId: config.userPoolClientId as string,
    userPoolId: config.userPoolId as string,
  }
}

export const addAmplifyConfiguration = (
  authInfo: Pick<
    DefaultAuthInfo,
    | 'identityProviders'
    | 'ssoDomain'
    | 'userPoolAppClientId'
    | 'userPoolId'
    | 'userPoolRegion'
  >
) => {
  let redirectUrl = window.location.origin

  if (!redirectUrl.includes('localhost')) {
    const { webRecord } = getResolvedDomains(window.location.hostname)
    redirectUrl = `https://${webRecord}`
  }

  Amplify.configure({
    Auth: {
      Cognito: {
        allowGuestAccess: false,
        identityPoolId: '',
        loginWith: {
          email: true,
          oauth: {
            domain: authInfo.ssoDomain,
            redirectSignIn: [redirectUrl],
            redirectSignOut: [redirectUrl],
            responseType: 'code',
            scopes: [
              'phone',
              'email',
              'profile',
              'openid',
              'aws.cognito.signin.user.admin',
            ],
          },
        },
        userPoolClientId: authInfo.userPoolAppClientId,
        userPoolId: authInfo.userPoolId,
      },
    },
  })
}

export const getUserMetaInfo = (
  tokenUserAttributes: FetchUserAttributesOutput | undefined,
  fetchAuthSessionResult: Awaited<ReturnType<typeof fetchAuthSession>>,
  accountUserAttributes: ApiUserProfile['userAttributes']
): {
  allowCreateTenant?: boolean
  isExternalManaged?: boolean
  isFederated?: boolean
  userSetupFinished?: boolean
} => {
  if (!tokenUserAttributes) return {}

  const userSetupFinishedCognito =
    userAttributesSchema.safeParse(tokenUserAttributes).success
  const userSetupFinishedAccount = userAttributesSchema.safeParse(
    accountUserAttributes
  ).success

  const userSetupFinished = Boolean(
    userSetupFinishedCognito || userSetupFinishedAccount
  )

  const idTokenPayload = fetchAuthSessionResult.tokens?.idToken?.payload

  const cognitoGroups: string[] = []
  const cognitoIdentities: unknown[] = []

  try {
    const rawGroups = idTokenPayload?.['cognito:groups']
    if (Array.isArray(rawGroups)) {
      for (const group of rawGroups) {
        if (typeof group === 'string') cognitoGroups.push(group)
      }
    }
  } catch {
    /* empty */
  }

  if (
    idTokenPayload?.['identities'] &&
    Array.isArray(idTokenPayload?.['identities'])
  ) {
    for (const identity of idTokenPayload['identities']) {
      if (typeof identity === 'object') cognitoIdentities.push(identity)
    }
  }

  const isExternalManaged = cognitoGroups.includes('scim')
  const isFederated = cognitoIdentities.length > 0
  const explicitAllowCreateTenant = cognitoGroups.includes(
    'allow-create-tenant'
  )

  const allowCreateTenant = Boolean(
    explicitAllowCreateTenant || !(isFederated || isExternalManaged)
  )

  return {
    allowCreateTenant,
    isExternalManaged,
    isFederated,
    userSetupFinished,
  }
}

export const getIdTokenJwt = async (): Promise<string | undefined> => {
  const tokens = await fetchAuthSession()
  return tokens?.tokens?.idToken?.toString()
}

export const clearAuthQueryStrings = () => {
  // clear "code", "state", "org", "username" and "password" query strings
  const url = new URL(window.location.href)
  url.searchParams.delete('authflow')
  url.searchParams.delete('code')
  url.searchParams.delete('state')
  url.searchParams.delete('org')
  url.searchParams.delete('username')
  url.searchParams.delete('password')
  window.history.replaceState({}, '', url.toString())
}

const L_PREFIX = 'CognitoIdentityServiceProvider.'

export const clearTokens = ({ username }: { username: string }) => {
  const { appClientId } = getCurrentAuthConfig()
  const baseKey = `${L_PREFIX}${appClientId}.${username}`
  localStorage.removeItem(`${baseKey}.idToken`)
  localStorage.removeItem(`${baseKey}.accessToken`)
  localStorage.removeItem(`${baseKey}.refreshToken`)
}

export type AuthSession = z.infer<typeof authSessionShape>

export type AuthSessionStorage = Record<string, AuthSession>
