export const DEV_ACCOUNT = '410352834984'

export const PROD_ACCOUNT = '183295438431'
export const PROD_LEGACY_ACCOUNT = '325108391604'

export const CI_ACCOUNT = '409186210785'

export const DEFAULT_REGION = 'eu-central-1'

const customEnvironments = ['ln', 'ap']

export type EMAIL_PROVIDER = 'RESEND' | 'SES' | 'BREVO'

export type DeploymentEnvironment = {
  apiRecord: string
  assetsRecord: string
  availableEmailProviders: EMAIL_PROVIDER[]
  awsAccountId: string
  cognitoFromEmailAddress: string
  cognitoRecord: string
  gitBranch: string
  hostedZoneName: string
  notificationFromEmailAdress: string
  scimRecord: string
  stage: string
  webRecord: string
  awsProfileName: string
}

const DevProfile = 'aoa-dev'
const CiProfile = 'aoa-ci'
const ProdLegacyProfile = 'aoa-prod'
const ProdProfile = 'aoa-prod-v2'

const environmentConfig = {
  dev: {
    apiRecord: 'api',
    assetsRecord: 'assets',
    availableEmailProviders: ['BREVO', 'RESEND'] satisfies EMAIL_PROVIDER[],
    awsAccountId: DEV_ACCOUNT,
    cognitoFromEmailAddress: 'no-reply@dev.growthsquare.cloud',
    cognitoRecord: 'auth',
    gitBranch: 'main',
    hostedZoneName: 'dev.growthsquare.cloud',
    notificationFromEmailAdress: 'no-reply@dev.growthsquare.cloud',
    scimRecord: 'scim',
    stage: 'dev',
    webRecord: '',
    awsProfileName: DevProfile,
  },
  prod: {
    apiRecord: 'api',
    assetsRecord: 'assets',
    availableEmailProviders: ['BREVO'] satisfies EMAIL_PROVIDER[],
    awsAccountId: PROD_ACCOUNT,
    cognitoFromEmailAddress: 'noreply@app.growthsquare.com',
    cognitoRecord: 'auth',
    gitBranch: 'main',
    hostedZoneName: 'app.growthsquare.com',
    notificationFromEmailAdress: 'noreply@app.growthsquare.com',
    scimRecord: 'scim',
    stage: 'prod',
    webRecord: '',
    awsProfileName: ProdProfile,
  },
  'prod-legacy': {
    apiRecord: 'api',
    assetsRecord: 'assets',
    availableEmailProviders: ['BREVO', 'RESEND'] satisfies EMAIL_PROVIDER[],
    awsAccountId: PROD_LEGACY_ACCOUNT,
    cognitoFromEmailAddress: 'no-reply@growthsquare.cloud',
    cognitoRecord: 'auth',
    gitBranch: 'main',
    hostedZoneName: 'growthsquare.cloud',
    notificationFromEmailAdress: 'no-reply@growthsquare.cloud',
    scimRecord: 'scim',
    stage: 'prod-legacy',
    webRecord: '',
    awsProfileName: ProdLegacyProfile,
  },
  staging: {
    apiRecord: 'api',
    assetsRecord: 'assets',
    availableEmailProviders: ['RESEND'] satisfies EMAIL_PROVIDER[],
    awsAccountId: DEV_ACCOUNT,
    cognitoFromEmailAddress: 'no-reply@staging.growthsquare.cloud',
    cognitoRecord: 'auth',
    gitBranch: 'main',
    hostedZoneName: 'staging.growthsquare.cloud',
    notificationFromEmailAdress: 'no-reply@staging.growthsquare.cloud',
    scimRecord: 'scim',
    stage: 'staging',
    webRecord: '',
    awsProfileName: DevProfile,
  },
} as const satisfies Record<string, DeploymentEnvironment>

/**
 * Returns the environment configuration for the given stage.
 */
export const getEnvironment = (stage: string): DeploymentEnvironment => {
  if (Object.keys(environmentConfig).includes(stage)) {
    return environmentConfig[stage as keyof typeof environmentConfig]
  }

  if (stage.startsWith('ci')) {
    return {
      apiRecord: `api-${stage}`,
      assetsRecord: 'assets-ci',
      availableEmailProviders: ['SES'] satisfies EMAIL_PROVIDER[],
      awsAccountId: CI_ACCOUNT,
      cognitoFromEmailAddress: 'no-reply@ci.growthsquare.cloud',
      cognitoRecord: `auth-${stage}`,
      gitBranch: 'main',
      hostedZoneName: 'ci.growthsquare.cloud',
      notificationFromEmailAdress: 'no-reply@ci.growthsquare.cloud',
      scimRecord: `scim-${stage}`,
      stage,
      webRecord: '*',
      awsProfileName: CiProfile,
    } satisfies DeploymentEnvironment
  }

  return {
    apiRecord: `api.${stage}`,
    assetsRecord: `assets.${stage}`,
    availableEmailProviders: ['RESEND', 'BREVO'],
    awsAccountId: DEV_ACCOUNT,
    cognitoFromEmailAddress: 'no-reply@dev.growthsquare.cloud',
    cognitoRecord: `auth.${stage}`,
    gitBranch: 'main',
    hostedZoneName: 'dev.growthsquare.cloud',
    notificationFromEmailAdress: 'no-reply@dev.growthsquare.cloud',
    scimRecord: `scim.${stage}`,
    stage,
    webRecord: `${stage}`,
    awsProfileName: DevProfile,
  } satisfies DeploymentEnvironment
}

/**
 * Returns the fully qualified domain name for the given environment.
 * @example
 * const environment = getEnvironment('dev')
 * const domainConfig = resolveFqdn(environment)
 */
export const resolveFqdn = (environment: DeploymentEnvironment) => {
  const {
    awsAccountId: accountId,
    apiRecord,
    assetsRecord,
    gitBranch: branch,
    hostedZoneName,
    webRecord,
    cognitoRecord: cognitoDomain,
    cognitoFromEmailAddress,
    notificationFromEmailAdress,
    stage,
    scimRecord,
    availableEmailProviders,
    awsProfileName,
  } = environment
  const result: DeploymentEnvironment = {
    apiRecord: apiRecord ? `${apiRecord}.${hostedZoneName}` : hostedZoneName,
    assetsRecord: assetsRecord
      ? `${assetsRecord}.${hostedZoneName}`
      : hostedZoneName,
    availableEmailProviders,
    awsAccountId: accountId,
    cognitoFromEmailAddress,
    cognitoRecord: cognitoDomain
      ? `${cognitoDomain}.${hostedZoneName}`
      : hostedZoneName,
    gitBranch: branch,
    hostedZoneName,
    notificationFromEmailAdress,
    scimRecord: `${scimRecord}.${hostedZoneName}`,
    stage,
    webRecord: stage.startsWith('ci')
      ? `${environment.stage}-latest.${hostedZoneName}`
      : webRecord
        ? `${webRecord}.${hostedZoneName}`
        : hostedZoneName,
    awsProfileName,
  }
  return result
}

let DEPLOYMENT_STAGE: string | undefined
if (!DEPLOYMENT_STAGE && typeof process !== 'undefined')
  DEPLOYMENT_STAGE = process.env['DEPLOYMENT_STAGE']
if (!DEPLOYMENT_STAGE && typeof global !== 'undefined')
  // biome-ignore lint/suspicious/noExplicitAny:
  DEPLOYMENT_STAGE = (global as any).DEPLOYMENT_STAGE

export const isLocalhost = (hostname: string) =>
  hostname.includes('localhost') || hostname.includes('127.0.0.1')

/**
 * Returns the deployment stage based on the hostname or the DEPLOYMENT_STAGE
 * environment variable.
 */
export const resolveDeploymentStage = (
  hostname?: string,
  ignoreDeploymentStageVariable?: boolean
): { domainPrefix?: string | undefined; stage: string } => {
  if (!ignoreDeploymentStageVariable && DEPLOYMENT_STAGE) {
    return { stage: DEPLOYMENT_STAGE }
  }

  if (!hostname) {
    return { stage: 'prod' }
  }

  if (isLocalhost(hostname)) {
    return { stage: 'dev' }
  }

  const webFQDN = hostname.replace('www.', '')

  if (webFQDN.endsWith('ci.growthsquare.cloud')) {
    const [prefix, ciPart] = webFQDN.split('ci-')
    const [ciNrRest, , ...stageOverrideRestParts] = ciPart?.split('-') ?? []
    const stageOverrideRest = stageOverrideRestParts.join('-').split('.')[0]
    const ciNr = ciNrRest?.split('.')[0]
    const domainPrefix = prefix?.endsWith('.') ? prefix.slice(0, -1) : prefix
    const stageOverride = stageOverrideRest?.split('.')[0]

    let stage: string | undefined
    if (stageOverride) {
      stage = stageOverride
    } else if (ciNr) {
      stage = `ci-${ciNr}`
    }

    if (stage && domainPrefix) return { domainPrefix, stage }
    if (stage) return { stage }
  }

  const stageLookup = [
    ...customEnvironments,
    ...Object.keys(environmentConfig),
  ].map((stage) => resolveFqdn(getEnvironment(stage)))

  const exactMatch = stageLookup.find((stage) => {
    return stage.webRecord === webFQDN
  })

  if (exactMatch) {
    return { stage: exactMatch.stage }
  }

  let partialMatch: { domainPrefix: string | undefined; stage: string } | null =
    null

  for (const stage of stageLookup) {
    const [tenantDomainPrefix, ...fqdn] = webFQDN.split('.')
    if (fqdn.join('.') === stage.webRecord)
      partialMatch = { domainPrefix: tenantDomainPrefix, stage: stage.stage }
  }

  if (partialMatch) {
    return partialMatch
  }

  return { stage: 'prod' }
}

/**
 * Get fully qualified domain names for the automatically determined
 * deployment stage.
 */
export const getResolvedDomains = (hostname?: string) => {
  const stage = resolveDeploymentStage(hostname)
  const resolved = resolveFqdn(getEnvironment(stage.stage))
  return {
    ...stage,
    ...resolved,
  }
}
