import { LoadingOverlay } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { nprogress } from '@mantine/nprogress'
import type { TranslationFunctions } from '@packages/i18n/src/i18n-types'
import AppWrapper from '@pages/AppWrapper'
import InvitationPage from '@pages/authentication/InvitationPage'
import OnboardingPage from '@pages/authentication/OnboardingPage'
import ErrorFallback from '@pages/error/ErrorFallback'
import TenantNotFoundPage from '@pages/error/TenantNotFoundPage'
import LinkMsTeamsPage from '@pages/organization/LinkMsTeamsPage'
import { type QueryClient, useQueryClient } from '@tanstack/react-query'
import type { ApiReturnTypes } from '@types'
import { getUserDisplayName, sleep } from '@utils'
import {
  checkIfPrefetching,
  getLoaderLocalStorageValue,
} from '@utils/localstorage-utils'
import { hasApiToken, trpc } from '@utils/trpc'
import {
  type ComponentType,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import {
  type LoaderFunction,
  type LoaderFunctionArgs,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useNavigate,
} from 'react-router-dom'

const Navigate = ({
  to,
  delay,
}: {
  readonly delay?: number
  readonly to?: string
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  useEffect(() => {
    void queryClient.invalidateQueries().then(() => {
      const locationQuery = new URLSearchParams(window.location.search).get(
        'location'
      )
      let navigateTo = to
        ? to
        : locationQuery
          ? decodeURIComponent(locationQuery)
          : '/'

      if (navigateTo.startsWith('/reload')) navigateTo = '/'

      void sleep(delay ?? 230).then(() =>
        navigate(navigateTo, { replace: true })
      )
    })
  }, [delay, navigate, queryClient, to])

  return (
    <div className='relative size-full'>
      <LoadingOverlay
        overlayProps={{
          blur: 2,
        }}
        visible
      />
    </div>
  )
}

type ExtendedLoaderParameters = LoaderFunctionArgs & {
  queryClient: QueryClient
  staleTime: number
  trpcContext: ReturnType<(typeof trpc)['useUtils']>
}

export type ExtendedLoaderFunction = (
  parameters: ExtendedLoaderParameters
) => ReturnType<LoaderFunction>

export type Handle = {
  crumb?: (parameters: { LL: TranslationFunctions; loaderData?: unknown }) => {
    crumb: ReactNode
    /**
     * On mobile show a back button instead of the crumb
     * @example { label: "Back to all projects" }
     */
    mobileBackLink?: {
      label: string
      to?: string
    }
  }
  crumbSettings?: {
    disabled?: boolean
  }
  pageOptions?: {
    className?: string
    padding?: 'none' | 'default'
  }
}

const Router = () => {
  const queryClient = useQueryClient()
  const trpcContext = trpc.useUtils()

  const loaderWrapper = useCallback(
    (loader?: ExtendedLoaderFunction) => {
      return async (loaderFunctionArgs: LoaderFunctionArgs) => {
        const hasTenantId = Boolean(localStorage.getItem('tenant-id'))
        if (!(hasTenantId && (await hasApiToken()))) return null

        if (!loader) {
          return null
        }

        const url = new URL(loaderFunctionArgs.request.url)

        // we cache the value for 1 minute
        const value = getLoaderLocalStorageValue(url.pathname)
        if (value !== undefined) return value

        const loaderPromise = loader({
          ...loaderFunctionArgs,
          queryClient,
          // we cache the value for 1 minute
          staleTime: 1_000 * 60,
          trpcContext,
        })

        const sleepPromise = sleep(1).then(() => {
          if (!checkIfPrefetching(url.pathname)) {
            throw new Error('starting navigation progress')
          }
        })

        let started = false

        // start nprogress if loader takes longer than 50ms
        Promise.race([loaderPromise, sleepPromise]).catch(() => {
          // sleepPromise resolved first, start the progress
          nprogress.set(25)
          nprogress.start()
          started = true
        })

        const loaderResult = await loaderPromise

        if (started) nprogress.complete()

        return loaderResult
      }
    },
    [queryClient, trpcContext]
  )

  const indexLoader = useCallback(
    (
      props: Promise<{
        default: ComponentType | null | undefined
        loader?: ExtendedLoaderFunction
      }>
    ) =>
      async () =>
        await props.then((result) => ({
          Component: Outlet,
          /**
           * Extends the default loader function with the queryClient and trpcContext,
           * and handles the nprogress bar while loading.
           */
          loader: loaderWrapper(result.loader),
        })),
    [loaderWrapper]
  )

  const importer = useCallback(
    (
      props: Promise<{
        default: ComponentType | null | undefined
        loader?: ExtendedLoaderFunction
      }>,
      componentOverride?: ComponentType
    ) =>
      async () =>
        await props.then((result) => ({
          Component: componentOverride ?? result.default,
          /**
           * Extends the default loader function with the queryClient and trpcContext,
           * and handles the nprogress bar while loading.
           */
          loader: loaderWrapper(result.loader),
        })),
    [loaderWrapper]
  )

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <Route
            element={
              <ModalsProvider>
                <Outlet />
              </ModalsProvider>
            }
            errorElement={<ErrorFallback root />}
          >
            <Route
              element={<InvitationPage />}
              errorElement={<ErrorFallback root />}
              path='invitation'
            />
            <Route
              element={<OnboardingPage />}
              errorElement={<ErrorFallback root />}
              path='onboarding'
            />
            <Route
              element={<LinkMsTeamsPage />}
              errorElement={<ErrorFallback root />}
              path='link-ms-teams'
            />
            <Route
              element={<TenantNotFoundPage />}
              errorElement={<ErrorFallback root />}
              path='org-not-found'
            />
            <Route
              element={<AppWrapper />}
              errorElement={<ErrorFallback root />}
              handle={
                {
                  crumb: ({ LL }) => ({
                    crumb: LL.navigation.sidebar.home(),
                  }),
                } satisfies Handle
              }
              path='/'
            >
              <Route
                errorElement={<ErrorFallback />}
                handle={
                  {
                    pageOptions: {
                      className: 'bg-[#f1f4f9]',
                    },
                  } satisfies Handle
                }
                index
                lazy={importer(import('@pages/home/IndexPage'))}
              />
              <Route
                handle={
                  {
                    crumb: ({ LL }) => ({
                      crumb: LL.navigation.sidebar.aoa.subItems.checkin(),
                    }),
                    crumbSettings: {
                      disabled: true,
                    },
                  } satisfies Handle
                }
                index
                lazy={importer(import('@pages/aoa/CheckinPage'))}
                path='checkin'
              />
              <Route
                errorElement={<ErrorFallback />}
                handle={
                  {
                    crumb: ({ LL }) => ({
                      crumb: LL.navigation.sidebar.aoa.label(),
                    }),
                  } satisfies Handle
                }
                lazy={indexLoader(import('@pages/aoa/OverviewPage'))}
                path='aoa'
              >
                <Route
                  handle={
                    {
                      crumb: ({ LL }) => ({
                        crumb: LL.navigation.sidebar.aoa.subItems.overview(),
                      }),
                      crumbSettings: {
                        disabled: true,
                      },
                      pageOptions: {
                        className: 'bg-[#f1f4f9]',
                      },
                    } satisfies Handle
                  }
                  index
                  lazy={importer(import('@pages/aoa/OverviewPage'))}
                />
                <Route
                  handle={
                    {
                      crumb: ({ LL }) => ({
                        crumb: LL.aoa.buttons.newAoa(),
                      }),
                      crumbSettings: {
                        disabled: true,
                      },
                    } satisfies Handle
                  }
                  index
                  lazy={importer(import('@pages/aoa/NewAoaPage'))}
                  path='new'
                />
                <Route
                  handle={
                    {
                      crumb: ({ LL }) => ({
                        crumb: LL.navigation.sidebar.aoa.subItems.filterPage(),
                      }),
                      crumbSettings: {
                        disabled: true,
                      },
                    } satisfies Handle
                  }
                  index
                  lazy={importer(import('@pages/aoa/FilterPage'))}
                  path='activity'
                />
                <Route
                  handle={
                    {
                      crumb: ({ LL }) => ({
                        crumb: LL.navigation.sidebar.aoa.subItems.heatmap(),
                      }),
                      crumbSettings: {
                        disabled: true,
                      },
                      pageOptions: { padding: 'none' },
                    } satisfies Handle
                  }
                  index
                  lazy={importer(import('@pages/aoa/HeatmapPage'))}
                  path='heatmap'
                />
                <Route
                  handle={
                    {
                      crumb: () => ({
                        crumb: 'Dev',
                      }),
                      crumbSettings: {
                        disabled: true,
                      },
                    } satisfies Handle
                  }
                  index
                  lazy={importer(import('@pages/aoa/DevPage'))}
                  path='devpage'
                />
                <Route
                  handle={
                    {
                      crumb: ({ LL, loaderData }) => ({
                        crumb:
                          (
                            loaderData as {
                              initialData:
                                | ApiReturnTypes['aoa']['getAoaDetails']
                                | undefined
                            } | null
                          )?.initialData?.title ?? LL.aoa.common.untitledAoa(),
                        mobileBackLink: {
                          label:
                            LL.navigation.sidebar.aoa.subItems.backToOverview(),
                        },
                      }),
                      pageOptions: {
                        className: 'bg-[#f1f4f9]',
                      },
                    } satisfies Handle
                  }
                  lazy={importer(import('@pages/aoa/DetailsPage'))}
                  path='details/:aoaId'
                />
              </Route>
              <Route
                handle={
                  {
                    crumb: ({ LL }) => ({
                      crumb: LL.navigation.sidebar.organization.label(),
                    }),
                    pageOptions: {
                      padding: 'none',
                    },
                  } satisfies Handle
                }
                lazy={importer(import('@pages/ai/AiChatLandingPage'))}
                path='ai'
              />
              <Route
                handle={
                  {
                    crumb: ({ LL }) => ({
                      crumb: LL.navigation.sidebar.organization.label(),
                    }),
                    pageOptions: {
                      padding: 'none',
                    },
                  } satisfies Handle
                }
                lazy={importer(import('@pages/ai/AiChatMessengerPage'))}
                path='ai/:chatId'
              />
              <Route
                errorElement={<ErrorFallback />}
                handle={
                  {
                    crumb: ({ LL }) => ({
                      crumb: LL.navigation.sidebar.organization.label(),
                    }),
                  } satisfies Handle
                }
                lazy={importer(
                  import('@pages/organization/OrganizationOverviewPage'),
                  Outlet
                )}
                path='organization'
              >
                <Route
                  handle={
                    {
                      crumb: ({ LL }) => ({
                        crumb:
                          LL.navigation.sidebar.organization.subItems.overview(),
                      }),
                      crumbSettings: {
                        disabled: true,
                      },
                      pageOptions: {
                        padding: 'none',
                      },
                    } satisfies Handle
                  }
                  index
                  lazy={importer(
                    import('@pages/organization/OrganizationOverviewPage')
                  )}
                />
                <Route
                  handle={
                    {
                      crumb: ({ LL }) => ({
                        crumb: 'Team',
                        mobileBackLink: {
                          label:
                            LL.navigation.sidebar.organization.subItems.backToOverview(),
                        },
                      }),
                      pageOptions: {
                        className: 'bg-[#f1f4f9]',
                      },
                    } satisfies Handle
                  }
                  lazy={importer(
                    import('@pages/organization/teams/TeamDetailPage')
                  )}
                  path='teams/:teamId'
                />
                <Route
                  handle={
                    {
                      crumb: ({ LL }) => ({
                        crumb:
                          LL.navigation.sidebar.organization.subItems.members(),
                      }),
                    } satisfies Handle
                  }
                  lazy={importer(
                    import('@pages/organization/OrganizationMembersPage'),
                    Outlet
                  )}
                  path='members'
                >
                  <Route
                    index
                    lazy={importer(
                      import('@pages/organization/OrganizationMembersPage')
                    )}
                  />
                  <Route
                    handle={
                      {
                        crumb: ({ loaderData, LL }) => ({
                          crumb: getUserDisplayName(
                            (
                              loaderData as {
                                initialData:
                                  | ApiReturnTypes['organization']['getMember']
                                  | undefined
                              } | null
                            )?.initialData?.userAttributes
                          ),
                          mobileBackLink: {
                            label:
                              LL.navigation.sidebar.organization.subItems.backToOverview(),
                          },
                        }),
                      } satisfies Handle
                    }
                    lazy={importer(
                      import('@pages/organization/members/MemberDetailPage')
                    )}
                    path=':userId'
                  />
                </Route>
              </Route>
              <Route
                errorElement={<ErrorFallback />}
                handle={
                  {
                    crumb: ({ LL }) => ({ crumb: LL.settingsPage.headline() }),
                    crumbSettings: {
                      disabled: true,
                    },
                  } satisfies Handle
                }
                lazy={importer(import('@components/layout/SettingsLayout'))}
                path='settings'
              >
                <Route
                  element={<Navigate delay={0} to='/settings/personal' />}
                  index
                />
                <Route
                  handle={
                    {
                      crumb: ({ LL }) => ({
                        crumb: LL.settingsPage.personal.headline(),
                      }),
                    } satisfies Handle
                  }
                  lazy={importer(
                    import('@pages/settings/PersonalSettingsPage')
                  )}
                  path='personal'
                />
                <Route
                  handle={
                    {
                      crumb: ({ LL }) => ({
                        crumb: LL.settingsPage.organization.headline(),
                      }),
                    } satisfies Handle
                  }
                  lazy={importer(
                    import('@pages/settings/OrganizationSettingsPage')
                  )}
                  path='organization'
                />
                <Route
                  handle={
                    {
                      crumb: ({ LL }) => ({
                        crumb: LL.settingsPage.methodology.headline(),
                      }),
                    } satisfies Handle
                  }
                  lazy={importer(
                    import('@pages/settings/MethodologySettingsPage')
                  )}
                  path='methodology'
                />
                <Route
                  handle={
                    {
                      crumb: ({ LL }) => ({
                        crumb: LL.settingsPage.organization.headline(),
                      }),
                    } satisfies Handle
                  }
                  lazy={importer(import('@pages/settings/TeamSettingsPage'))}
                  path='organization/team'
                />
                <Route
                  handle={
                    {
                      crumb: ({ LL }) => ({
                        crumb: LL.settingsPage.notifications.headline(),
                      }),
                    } satisfies Handle
                  }
                  lazy={importer(
                    import('@pages/settings/NotificationSettingsPage')
                  )}
                  path='notifications'
                />
              </Route>
              <Route
                element={<Navigate />}
                errorElement={<ErrorFallback />}
                path='reload'
              />
            </Route>
          </Route>
        )
      ),
    [importer, indexLoader]
  )

  return <RouterProvider router={router} />
}

export default Router
