import { AuthContext } from '@web/services/AuthContext'
import { useContext } from 'react'
import type { useProvideAuth } from './useProvideAuth'

type UseProvideAuth = ReturnType<typeof useProvideAuth>

export const useAuth = <TUnauthenticated extends boolean>() =>
  useContext(AuthContext) as TUnauthenticated extends true
    ? UseProvideAuth
    : UseProvideAuth & {
        user: NonNullable<UseProvideAuth['user']>
        userId: string
      }
