import { trpc } from '@utils/trpc'
import {
  type ReactElement,
  type ReactNode,
  createContext,
  useContext,
} from 'react'
import { useAuth } from './useAuth'

const useProvideUpdateUserAttributes = () => {
  const { userAttributes, reloadAccount } = useAuth()

  const utils = trpc.useUtils()

  const { mutateAsync, isPending } =
    trpc.account.updateUserAttributes.useMutation({
      onSettled: async () => {
        await Promise.all([
          reloadAccount({ forceRefresh: true }),
          utils.organization.invalidate(),
          utils.account.invalidate(),
        ])
      },
    })

  return { userAttributes, updateUserAttributes: mutateAsync, isPending }
}

const UpdateUserAttributesContext = createContext({})

export const UpdateUserAttributesProvider = (props: {
  children: ReactNode
}): ReactElement => {
  const value = useProvideUpdateUserAttributes()

  return (
    <UpdateUserAttributesContext.Provider value={value}>
      {props.children}
    </UpdateUserAttributesContext.Provider>
  )
}

export const useUpdateUserAttributes = () =>
  useContext(UpdateUserAttributesContext) as ReturnType<
    typeof useProvideUpdateUserAttributes
  >
