import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/notifications/styles.css'
import '@mantine/nprogress/styles.css'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'
import { initSentry } from './sentry'

if (import.meta.env.DEV) {
  // scan({
  //   enabled: true,
  // })
}

initSentry()

const strict = import.meta.env['VITE_DISABLE_STRICT_MODE'] !== 'true'

createRoot(document.querySelector('#root') as HTMLElement).render(
  strict ? (
    <StrictMode>
      <App />
    </StrictMode>
  ) : (
    <App />
  )
)
